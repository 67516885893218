/**
 * cards store
 * @typedef {Object}
 * @property {state} state
 */
const cards = {
    namespaced: true,
    /**
     * @typedef {Object} state
     * @property {Object} allCards
     * @property {Object} currentCard
     * @property {Object} transactions
     * @returns {state}
         */
    state: {
        cards: {},
        currentCard: {},
        transactions: {}
    },
    getters: {
        /**
         * get all cards data
         * @param {state} state
         * @returns {state['getAllCards']}
         */
        getAllCards(state) {
            return state.cards;
        },

        /**
         * get card data
         * @param {state} state
         * @returns {state['getCurrentCardDetails']}
         */
        getCurrentCardDetails(state) {
            return state.currentCard;
        },

        /**
         * get card data
         * @param {state} state
         * @returns {state['getTransactions']}
         */
        getTransactions(state) {
            return state.transactions;
        }

    },
    mutations: {
        /**
         * set all card data
         * @param {state} state
         * @param {state['setAllCards']} payload
         */
        setAllCards(state, payload) {
            state.cards = payload;
        },

        /**
         * set current card data
         * @param {state} state
         * @param {state['setCurrentCard']} payload
         */
        setCurrentCardDetails(state, payload) {
            state.currentCard = payload;
        },

        /**
         * set current card data
         * @param {state} state
         * @param {state['setTransactions']} payload
         */
        setTransactions(state, payload) {
            state.transactions = payload;
        },
    },
    actions: {
        /**
         * get all cards list 
         */
        getCardsList({ commit }) {
            return new Promise((resolve, reject) => {
                this._vm.$http
                    .get('/v1/solidcard/')
                    .then(({ data }) => {
                        commit('setAllCards', data.data);
                        resolve(data.data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        /**
         * get card details
         * @param {*} param0
         * @param {string} cardId
         */
        getCardDetails({ commit }, cardId) {
            return new Promise((resolve, reject) => {
                this._vm.$http
                    .get('/v1/solidcard/'+cardId)
                    .then(({ data }) => {
                        commit('setCurrentCardDetails', data);
                        resolve(data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        /**
         * get card details
         * @param {*} param0
         * @param {string} cardId
         */
        fetchAllTransactions({ commit }, cardId) {
            return new Promise((resolve, reject) => {
                this._vm.$http
                    .get('/v1/solidcard/'+cardId+'/transaction')
                    .then(({ data }) => {
                        commit('setTransactions', data);
                        resolve(data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        /**
         * fetch uniqe token for vgs show
         * @param {*} _ 
         * @param {*} cardId 
         * @returns 
         */
        showToken(_, cardId) {
            return new Promise((resolve, reject) => {
                this._vm.$http
                    .post(`/v1/solidcard/${cardId}/showtoken`)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
    }
};

export default cards;
