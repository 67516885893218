import store from '@/store';

/**
 * get env variables
 * @param {*} name
 */
export const getEnv = (name = undefined) => {
    const activeEnv = store.getters['getActiveEnv'];
    if (activeEnv) {
            const env = CONFIG_ENV; // eslint-disable-line
        // eslint-disable-next-line no-process-env
        if (name) return env[activeEnv][name] || process.env[name];
        else return env[activeEnv];
    } else {
        // eslint-disable-next-line no-process-env
        return process.env[name];
    }
};

/**
 * get supported countries
 */
export const getSupportedCountries = () => {
    // const supportedCountries = ['US', 'IN'];
  
    const countries = [{
        mask: '(###) ###-####',
        dial_code: '+1',
        name: 'United States',
        code: 'US',
        identityMask: {
            type: 'ssn',
            last4: '####',
            full: '###-##-####'
        }
    },
    {
        mask: '##########',
        dial_code: '+91',
        name: 'India',
        code: 'IN',
        identityMask: {
            type: 'passport',
            last4: 'XXXX',
            full: Array.apply(null, Array(17)).map((_, i) => 'X'.repeat(i+4)) //min 4, max 20
        }
    }];
  
    // return countries.filter(c => supportedCountries.includes(c.code))

    return countries;
}