import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
Vue.use(Router);

const routes = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Dashboard',
            component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login-sms',
            name: 'LoginViaUrl',
            component: () => import(/* webpackChunkName: "LoginViaUrl" */ '@/views/LoginViaUrl.vue')
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
        },
        {
            path: '/verification',
            name: 'Verification',
            component: () => import(/* webpackChunkName: "verification" */ '@/views/Verification.vue')
        },
        {
            path: '/card/:id',
            name: 'Card Details',
            component: () => import(/* webpackChunkName: "verification" */ '@/views/CardDetails.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login-callback',
            name: 'LoginCallback',
            component: () => import(/* webpackChunkName: "LoginCallback" */ '@/views/LoginCallback.vue')
        },
        {
            path: '/faq',
            name: 'FAQ',
            component: () => import(/* webpackChunkName: "LoginCallback" */ '@/views/FAQ.vue')
        },
        {
            path: '/:catchAll(.*)',
            name: 'NotFound',
            component: () => import(/* webpackChunkName: "NotFoundCallback" */ '@/views/404.vue')
        }
    ],
});

routes.beforeEach((to, from, next) => {
    let loggedIn = store.getters['auth/isAuthenticated'];
    console.log('loggedIn', loggedIn)
    if (
        to.matched.some((record) => record.meta.requiresAuth)
    ) {
        if (!loggedIn) {
            next({
                path: '/login'
            });
        } else {
            next();
        }
    }

    window.scrollTo(0, 0);
    next();
});


export default routes;