import Vue from 'vue';
import {
    formatDate,
    transactionAmount,
    currencyFormatter,
    capitalizeFirstLetter,
    formatPhoneNumber
} from '../utils/general';

Vue.filter('date', formatDate);
Vue.filter('transactionAmount', transactionAmount);
Vue.filter('currency', currencyFormatter);
Vue.filter('phone', formatPhoneNumber);
Vue.filter('capitalizeFirstLetter', capitalizeFirstLetter);
