<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
    name: 'App',
    mounted() {
        let everythingLoaded = setInterval(function() {
            if (/loaded|complete/.test(document.readyState)) {
                clearInterval(everythingLoaded);
                if(window.wiseAppinit) {
                    window.wiseAppinit()
                }
            }
        }, 10);
    }
};
</script>
