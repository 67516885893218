import { WebAuth } from 'auth0-js';

/**
 * auth client
 * @see {@link https://auth0.com/docs/libraries/auth0js}
 * @see {@link https://auth0.com/docs/connections/passwordless/embedded-login/native}
 */
const authClient = new WebAuth({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin + '/login-callback',
    responseType: 'token id_token',
    audience: process.env.VUE_APP_AUTH0_AUDIENCE
});

/**
 * auth store
 * @typedef {Object}
 * @property {state} state
 */
const auth = {
    namespaced: true,
    /**
     * @typedef {Object} state
     * @property {string} phoneNumber
     * @property {boolean} isAuthenticated
     * @property {token} token
     * 
     * @typedef {Object} token
     * @property {string | null} accessToken
     * @property {string | null} expiresIn
     * @property {string | null} idToken
     * @returns {state}
     */
    state: () => ({
        phoneNumber: null,
        isAuthenticated: false,
        token : {
            accessToken: null,
            expiresIn: null,
            idToken: null,
            refreshToken: null,
            scope: null,
            state: null,
            tokenType: null
        }
    }),
    mutations: {
        /**
         * set phone number
         * @param {state} state 
         * @param {*} payload 
         */
        setPhoneNumber(state, payload) {
            state.phoneNumber = payload;
        },
        /**
         * set tokens
         * @param {state} state
         * @param {Object} payload
         */
        setTokens(state, payload) {
            state.token = payload;
        },
        /**
         * set isAuthenticated
         * @param {state} state
         * @param {boolean} payload
         */
        setIsAuthenticated(state, payload) {
            state.isAuthenticated = payload;
        }
    },
    actions: {
        /**
         * send otp to user phone
         * @param {*} phoneNumber 
         * @returns {Promise<any>}
        */
        sendOtp({commit}, phoneNumber) {
            return new Promise((resolve, reject) => {
                authClient.passwordlessStart({
                    connection: 'sms',
                    send: 'code',
                    phoneNumber
                }, (error, result) => {
                    commit('setPhoneNumber', phoneNumber);
                    if(error) {
                        reject(error);
                    } else {
                        resolve(result)
                    }
                })
            })
        },
        /**
         * send otp to user phone
         * @param {*} phoneNumber 
         * @returns {Promise<any>}
        */
        verifyOtp(_, {phoneNumber,verificationCode }) {
            return new Promise((resolve, reject) => {
                authClient.passwordlessLogin({
                    connection: 'sms',
                    phoneNumber,
                    verificationCode
                }, (error, result) => {
                    if(error) {
                        reject(error);
                    } else {
                        resolve(result);
                    }
                })
            })
        },
        /**
         * logout
         */
        logout({commit}) {
            commit('setIsAuthenticated', false);
            commit('setTokens', {
                accessToken: null,
                expiresIn: null,
                idToken: null
            });
            authClient.logout({
                clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
                redirectUri: window.location.origin + '/login',
            })
        },
        /**
         * parse token
         */
        parseToken({commit}) {
            return new Promise((resolve, reject) => {
                authClient.parseHash({ hash: window.location.hash }, (err, authResult) => {
                    if (err || !authResult) {
                        commit('setTokens', null);
                        commit('setIsAuthenticated', false);
                        console.log(err);
                        reject(err || 'Something went wrong');
                    } else {

                        /** @type {import('axios').AxiosInstance} */
                        const http = this._vm.$http;
                        http.defaults.headers.common['sd-solid-cards-authorization'] = 'Bearer ' + authResult.accessToken;
                        window.sessionStorage.setItem('_auth_details', JSON.stringify(authResult));
                        commit('setTokens', authResult);
                        commit('setIsAuthenticated', true);
                        resolve(authResult);
                    }
                })
            })
        },
        /**
         * check session
         */
        checkSession({commit}) {
            return new Promise((resolve, reject) => {
                authClient.checkSession({}, function(err, authResult) {
                    if (err || !authResult) {
                        commit('setTokens', null);
                        commit('setIsAuthenticated', false);
                        reject(err || 'Something went wrong');
                    } else {
                        commit('setTokens', authResult);
                        commit('setIsAuthenticated', true);
                        resolve(authResult);
                    }
                })
            })
        }
    },
    getters: {
        /**
         * get phoneNumber
         * @param {*} state 
         */
        phoneNumber(state) {
            return state.phoneNumber;
        },
        /**
         * get isAuthenticated
         * @param {*} state 
         */
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        /**
         * token details
         */
        token(state) {
            return state.token;
        }
    }
};

export default auth;