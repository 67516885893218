import { format } from 'date-fns';

/**
 * transaction amount
 * @param {string} amount
 * @returns {string}
 */
export const transactionAmount = (amount) => {
    if (amount.startsWith('-') || amount.startsWith('+')) {
        const index = 1;
        return amount.substring(0, index) + '' + currencyFormatter(amount.substr(index));
    }
    return '+' + currencyFormatter(amount);
};


export const currencyFormatter = (value) => {
  
    let number = value;
  
    if(typeof number === 'string') {
        number = number.replace('$', '');
        number = parseFloat(number);
    }
  
    if(isNaN(number)) {
        return value;
    }
  
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    
    return formatter.format(number);
};

/**
 * format date
 */
export const formatDate = (date, f = 'yyyy-MM-dd') => {
    return format(new Date(date), f);
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};


export const formatPhoneNumber = (phoneNumber) => {

    const dial_code = '+1';
    const phoneNumberWithoutCode = (''+phoneNumber).replace(dial_code, '').replace(/ /g,'');
    let mask = '(###) ###-####';
  
    let i = 0;
    const numbers = mask.split('').map((c) => {
        if(c === '#') {
            const returnValue = phoneNumberWithoutCode[i] || '';
            i++;
            return returnValue;
        }
        return c;
    }).join('');
    return dial_code + ' ' + numbers;
};