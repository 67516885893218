import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

/**
 * load messages from locale files
 * @returns {Object} messages
 */
function loadLocaleMessages() {

    const localesNew = require.context(
        '../locales',
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};

    localesNew.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = localesNew(key);
        }
    });

    return messages;
}

export default new VueI18n({
    locale: 'en',
    messages: loadLocaleMessages()
});