// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import store from './store';
import router from './router';
import i18n from './setup/i18n.js';
import ElementUI from 'element-ui';
import './mixins';
import axios from './plugins/axios';
import { getEnv } from './utils/env';

// CSS
import '@/assets/scss/index.scss';

// Mixins
import './mixins';
import './filters';

Vue.use(axios);
Vue.use(ElementUI);

Vue.config.productionTip = false;

/**
 * web app initilize
 * @param {string=} from - webapp or sdk
 */
function wiseAppinit() {
    /**
     * verygoodvault
     */
    console.log('wiseAppinit init');
    window.addVgsScript = function(callback) {
        const vgsScript = document.createElement('script');
        vgsScript.id = 'vgs-script';
        const orgId = getEnv('VUE_APP_VGS_ORG_ID');
        vgsScript.src =
            'https://js.verygoodvault.com/vgs-show/1.3/'+orgId+'.js';
        vgsScript.type = 'text/javascript';
        if(callback)
            vgsScript.onload = callback;
        document.head.append(vgsScript);
    }
}

/**
 * session check
 */
(function(){
    let authDetails =  window.sessionStorage.getItem('_auth_details');
    const token = store.getters['auth/token'];
    if(token.accessToken === null && authDetails) {
        try {
            authDetails = JSON.parse(authDetails);
            if(authDetails.accessToken) {
                store.commit('auth/setTokens',authDetails);
                store.commit('auth/setIsAuthenticated',true);

                Vue.$http.defaults.headers.common['sd-solid-cards-authorization'] = 'Bearer ' + authDetails.accessToken;
            } 
        } catch {
            console.log('Invalid token');
        }
    }
})();

/* eslint-disable no-new */
new Vue({
    render: h => h(App),
    router,
    i18n,
    store,
    template: '<App/>',
    components: { App}
}).$mount('#app');

window.wiseAppinit = wiseAppinit;