import { Loading } from 'element-ui';

const loadingOptions = {
    lock: true,
    background: 'rgba(255, 255, 255, 0.7)'
};
/**
 * show loader
 */
export function showLoader() {
    const loadingInstance = Loading.service(loadingOptions);
    return loadingInstance;
}
