import axios from 'axios';

function redirectToLogin() {
    const router = require('../router').default;
    router.push('/login');
}

export default {
    setup: function () {
        const Axios = axios.create();
        Axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
        Axios.defaults.headers.common.Accept = 'application/json';
        Axios.interceptors.request.use(function (config) {
            config.url = process.env.VUE_APP_API_BASE_URL + config.url;
            return Promise.resolve(config);
        });

        return Axios;
    },
    install: function (Vue) {
        const Axios = this.setup();
        /**
         * http method
         */
        Vue.prototype.$http = Axios;
        Vue.$http = Axios;

        this.createAxiosResponseInterceptor(Axios);
    },
    /**
     * get refresh token
     * @see {@link https://auth0.com/docs/security/tokens/refresh-tokens/use-refresh-tokens}
     * @param {Object} Axios
     * @param {Function}
     * @returns {Promise<NewAccessToken>}
     */
    createAxiosResponseInterceptor: function (Axios) {
        const interceptorObj = Axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (!error.response) {
                    return Promise.reject(error);
                }
                if (error.response.status !== 401) {
                    return Promise.reject(error);
                }

                /*
                * When response code is 401, try to refresh the token.
                * Eject the interceptor so it doesn't loop in case
                * token refresh causes the 401 response
                */
                Axios.interceptors.response.eject(interceptorObj);

                const store = require('../store');
                const checkSession = store.default.dispatch('auth/checkSession');

                checkSession()
                    .then((data) => {
                        error.response.config.headers.common['sd-solid-cards-authorization'] =
                'Bearer ' + data.accessToken;
                        Axios.defaults.headers.common['sd-solid-cards-authorization'] =
                'Bearer ' + data.accessToken;
                        let newURL = error.response.config.url.replace(
                            process.env.VUE_APP_API_BASE_URL,
                            ''
                        );
                        error.response.config.url = newURL;
                        return Axios(error.response.config);
                    }).catch((error) => {
                        redirectToLogin();
                        return Promise.reject(error);
                    })
                    .finally(() => {
                        this.createAxiosResponseInterceptor(Axios);
                    });
            }
        );
    }
};
